import React from 'react'
import Layout from '../../components/layout'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Seo from '../../components/seo'
import PageHeader from '../../components/pageHeader'
const Undervisnigsplan = (props) => (
  <Layout>
    <Seo title='Undervisningsplan' />
    <PageHeader text='Undervisningsplan & kursplaner' />
    <div className='container'>
      <div className='row'>
        <div className='col-12 col-sm-12 col-md-12col-lg-12 col-xl-12'>
          <Img fixed={props.data.imageTwo.childImageSharp.fixed} alt='transportstyrelsen' className='rounded' />

          <div>
            <p>På trafikskolan har vi en undervisningsplan som är godkänd av transportstyrelsen. Undervisningplanen bygger på transportstyrelsens kursplan. Som vi skyldiga att följa, men vissa saker kanske du redan kan om du kört privat innan du börjar på trafikskolan. Av erfarenhet vet vi dock att det nästan alltid är bäst att börja på en trafikskola så att du undviker att lära dig "fel saker i fel ordning".</p>
            <p>Det är viktigt att både elev och handledare är medvetna om vilka krav vi som utbildare har. Som inskriven elev hos oss får man tillgång till STRs elevcentral. Här ifrån kan både elev och handledare följa utbildningen efter utbildningsplanen. Vi har 15 moment med respektive delmoment. Här kan du se vilka moment och delmoment som du gjort enligt din undervisningsplan. Ej utförda moment är gråa, avklarade moment är gröna och delvis avklarade är orange.</p>
            <p>Målet för utbildningen är att alla moment är godkända innan eleven gör sitt förarprov</p>
          </div>
        </div>
        <div className='col-12 col-sm-12 col-md-12col-lg-12 col-xl-12 mt-1 pb-5'>
          {/* <img src={require('../../images/information/utbildningsplan.png')} className='img-fluid w-100' alt='utbildningsplan' /> */}
          <Img fluid={props.data.imageOne.childImageSharp.fluid} alt='utbildningsplan' className='rounded' />
        </div>
      </div>
    </div>
  </Layout>
)

export default Undervisnigsplan
export const pageQuery = graphql`
query {
  imageOne: file(relativePath: { eq: "information/utbildningsplan.png" }) {
    childImageSharp {
      fluid(maxWidth: 10000) {
        ...GatsbyImageSharpFluid
      }
    }
  }

  imageTwo: file(relativePath: { eq: "information/transportstyrelsen.png" }) {
    childImageSharp {
      fixed(width: 172, height: 62) {
        ...GatsbyImageSharpFixed
      }
    }
  }
}
`
